<template>
    <div class="container">
        <Audit v-model:auditId="auditId" @onSuccess="getTabelData" />
        <Detail v-model:detailId="detailId" :status="status" />
        <!-- <a-tabs v-model:activeKey="status" @change="changeTabStatus">
            <a-tab-pane
                v-for="item in statusOptions"
                :key="item.key"
                :tab="item.value + '报工'"
            ></a-tab-pane>
        </a-tabs> -->
        <FilterBlock
            @search="search"
            :teamOptions="teamOptions"
            :submitUserOptions="submitUserOptions"
            :produceOptions="produceOptions"
        />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 2000 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'workOrderCode'">
                        {{ record?.workOrderInfo?.code }}
                    </template>
                    <template v-if="column.dataIndex === 'projectCode'">
                        {{ record?.workOrderInfo?.projectCode }}
                    </template>
                    <template v-if="column.dataIndex === 'projectName'">
                        {{ record?.workOrderInfo?.projectName }}
                    </template>
                    <template v-if="column.dataIndex === 'productName'">
                        {{ record?.workOrderInfo?.productName }}
                    </template>
                    <template v-if="column.dataIndex === 'productCode'">
                        {{ record?.workOrderInfo?.productCode }}
                    </template>
                    <template v-if="column.dataIndex === 'produceTeamName'">
                        {{ record?.workOrderInfo?.produceTeamName }}
                    </template>
                    <template v-if="column.dataIndex === 'workReportUsers'">
                        {{ record?.workReportUsers?.length }}
                    </template>
                    <template v-if="column.dataIndex === 'hourlyWorkerCount'">
                        {{ record?.hourlyWorkerCount || 0 }}
                    </template>
                    <template v-if="column.dataIndex === 'reportDate'">
                        {{ formateMoment(record?.productionStartTime, "YYYY-MM-DD") }}
                    </template>
                    <template v-if="column.dataIndex === 'reportTime'">
                        {{ formateMoment(record?.productionStartTime, "HH:mm") }} ~
                        {{ formateMoment(record?.productionEndTime, "HH:mm") }}
                    </template>
                    <template v-if="column.dataIndex === 'livePictureIds'">
                        <a
                            v-if="record?.livePictureIds?.length"
                            @click="handleViewPic(record.id, record?.livePictureIds)"
                        >
                            查看({{ record?.livePictureIds?.length }})
                        </a>
                        <span v-else style="color: rgba(0, 0, 0, 0.3)">暂无图片</span>
                        <div style="display: none" v-if="previewId == record?.id">
                            <a-image-preview-group
                                :preview="{
                                    visible: Boolean(previewId == record?.id),
                                    onVisibleChange: vis => (previewId = null),
                                }"
                            >
                                <a-image
                                    v-for="item in record?.livePictureIds"
                                    :key="item"
                                    :src="`${baseURL}/web/mes-file-info/download/${item}`"
                                />
                            </a-image-preview-group>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => (detailId = record.id)">详情</a>
                            <!-- <a
                                @click="() => (auditId = record.id)"
                                v-show="status == 'WAIT_AUDIT'"
                                v-perm="'btn:work-order-step-report-work-audit'"
                            >
                                审核
                            </a> -->
                        </a-space>
                    </template>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import Audit from "./audit";
import { formateMoment, showMessage } from "@/utils/common";
import { apiJCWorkReportList, apiJCWorkReportIndexData } from "@/api";
import { baseURL } from "@/utils/config";
export default defineComponent({
    components: {
        FilterBlock,
        Detail,
        Audit,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "报工流水号",
                dataIndex: "code",
            },
            {
                title: "工单号",
                dataIndex: "workOrderCode",
            },
            {
                title: "产品名称",
                dataIndex: "productName",
            },
            {
                title: "产品编码",
                dataIndex: "productCode",
            },
            {
                title: "生产班组",
                dataIndex: "produceTeamName",
            },
            {
                title: "提交人",
                dataIndex: "creatorDisplayName",
            },
            {
                title: "报工工序",
                dataIndex: "workOrderStepName",
            },
            {
                title: "生产人数",
                dataIndex: "workReportUsers",
            },
            {
                title: "小时工人数",
                dataIndex: "hourlyWorkerCount",
            },
            {
                title: "生产日期",
                dataIndex: "reportDate",
            },
            {
                title: "生产时间",
                dataIndex: "reportTime",
            },
            {
                title: "生产时长(/h)",
                dataIndex: "reportDurationHours",
            },
            {
                title: "生产总工时(/h)",
                dataIndex: "reportDurationTotalHours",
            },
            {
                title: "异常时长(/h)",
                dataIndex: "exceptionWorkingHours",
            },
            {
                title: "异常总工时(/h)",
                dataIndex: "exceptionTotalWorkingHours",
            },
            {
                title: "现场图片",
                dataIndex: "livePictureIds",
                width: 100,
            },
            {
                title: "提交时间",
                dataIndex: "createdTime",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 100,
            },
        ];

        const state = reactive({
            status: "",
            statusOptions: [],
            viewPicVisible: false,
            previewId: null,
            teamOptions: [],
            submitUserOptions: [],
            produceOptions: [],
            detailId: null,
            auditId: null,
            params: null,
            data: [],
            loading: false,
            curLivePictureIds: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiJCWorkReportList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: "PASSED",
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const changeTabStatus = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const handleViewPic = (id, pics) => {
            if (!pics.length) return showMessage("info", "暂无图片");
            state.previewId = id;
        };

        const getIndexData = async () => {
            let res = await apiJCWorkReportIndexData();
            if (res.status === "SUCCESS") {
                state.teamOptions = res.data?.teamCodeAndNameCandidate;
                state.submitUserOptions = res.data?.creatorIdAndNameCandidate;
                state.produceOptions = res.data?.stepCodeAndNameCandidate;
                state.statusOptions = res.data?.statusList;
                if (state.statusOptions?.length) {
                    state.status = state.statusOptions[0].key;
                    getTabelData();
                }
            }
        };
        getIndexData();

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            changeTabStatus,
            getTabelData,
            changeTabStatus,
            formateMoment,
            handleViewPic,
            baseURL,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    // display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
