<template>
    <a-modal
        v-model:visible="visible"
        title="报工审核"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="600px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="是否通过"
                name="pass"
                :rules="[{ required: true, message: '请选择是否通过' }]"
            >
                <a-radio-group v-model:value="formData.pass">
                    <a-radio :value="true">是</a-radio>
                    <a-radio :value="false">否</a-radio>
                </a-radio-group>
            </a-form-item>

            <a-form-item
                label="驳回原因"
                name="rejectReason"
                :rules="[
                    { required: !formData.pass, message: '请输入驳回原因' },
                    { validator: checkSpaceStartEnd },
                ]"
            >
                <a-textarea
                    v-model:value="formData.rejectReason"
                    placeholder="最多可输入800字"
                    :disabled="formData.pass"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiJCWorkReportAudit } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
export default defineComponent({
    props: ["auditId"],
    emits: ["onSuccess"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            formRef: null,
            formData: {
                id: "",
                pass: "",
                rejectReason: "",
            },
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            emit("update:auditId", null);
        };

        const onFinish = async () => {
            let res = await apiJCWorkReportAudit(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("onSuccess");
            }
        };

        const checkSpaceStartEnd = (rule, value) => {
            if (value && !checkValueIsAvailable(value, "spaceStartEnd")) {
                return Promise.reject("不能以空格开头或结尾");
            } else {
                return Promise.resolve();
            }
        };

        watch(
            () => props.auditId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    state.formData.id = newVal;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkSpaceStartEnd,
        };
    },
});
</script>
